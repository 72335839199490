import React from "react";
import { faBookmark as fasBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MedcertRow = ({ medcert, callbackFunction, changePriority, isAdmin }) => {
  let {
    dateIssued,
    employee,
    hospitalName,
    status,
    isPriority,
    purpose,
    numberOfLeaves,
  } = medcert;

  // formatting for date and time
  let dateFormat = { month: "short", day: "numeric" };
  dateIssued = new Date(Date.parse(dateIssued)).toLocaleDateString(
    [],
    dateFormat
  );
  // let timeCreated = new Date(Date.parse(created)).toLocaleTimeString()

  return (
    <>
      <tr>
        {status === "Pending" && (
          <td>
            <button
              type="button"
              className="button"
              onClick={() => changePriority(medcert, !isPriority)}
            >
              <span className="icon is-small">
                <FontAwesomeIcon
                  icon={isPriority ? fasBookmark : faBookmark}
                  color={isPriority ? "#F57F20" : ""}
                />
              </span>
            </button>
          </td>
        )}
        <td className="is-selectable" onClick={callbackFunction}>
          {dateIssued}
        </td>
        <td className="is-selectable" onClick={callbackFunction}>
          {employee.name}
        </td>
        <td className="is-selectable" onClick={callbackFunction}>
          {employee.department}
        </td>
        <td className="is-selectable" onClick={callbackFunction}>
          {purpose}
        </td>
        <td className="is-selectable" onClick={callbackFunction}>
          {hospitalName}
        </td>
        <td className="is-selectable" onClick={callbackFunction}>
          {numberOfLeaves}
        </td>
      </tr>
    </>
  );
};

export default MedcertRow;
