import React from "react";
import classNames from "classnames";

const DataTable = ({
  headerTitles,
  children,
  headerStyles,
  tableClassNames,
}) => (
  <table
    className={classNames("table is-fullwidth is-hoverable", tableClassNames)}
  >
    <thead>
      <tr>
        {headerTitles.map((title, index) => (
          <th key={index} className={headerStyles}>
            {title}
          </th> //instance
        ))}
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
);

export default DataTable;
