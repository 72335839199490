import React, { useState, useEffect } from "react";

const Pagination = ({ pages, callbackFunction }) => {
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    setActivePage(1);
  }, [pages]);

  return (
    <nav
      className="pagination is-centered"
      role="navigation"
      aria-label="pagination"
    >
      <ul className="pagination-list">
        <li>
          <button
            className={`pagination-link ${activePage === 1 && "is-current"}`}
            onClick={() => {
              setActivePage(1);
              callbackFunction(1);
            }}
          >
            First Page
          </button>
        </li>
        <li>
          <button
            className="pagination-link"
            onClick={() => {
              setActivePage(activePage - 1);
              callbackFunction(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            Previous Page
          </button>
        </li>
        <li>
          <h5 className="subtitle mx-3">
            Displaying page <b>{activePage}</b> of <b>{pages}</b>
          </h5>
        </li>
        <li>
          <button
            className="pagination-link"
            onClick={() => {
              setActivePage(activePage + 1);
              callbackFunction(activePage + 1);
            }}
            disabled={activePage === pages}
          >
            Next Page
          </button>
        </li>
        <li>
          <button
            className={`pagination-link ${activePage === pages &&
              "is-current"}`}
            onClick={() => {
              setActivePage(pages);
              callbackFunction(pages);
            }}
          >
            Last Page
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
