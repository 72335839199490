import React, { useState, useEffect, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from "./forms.module.scss";

import api from "../../../services/api";

// Usage:
// <SearchBar
//   type="text"
//   name="employeeSearch"
//   endpoint="employees"
//   placeholder="Search for employee..."
//   resultDisplay={["first_name", "last_name"]}
// />
//
//  name: name of input
//  endpoint: taken from psychic-invention url
//  resultDisplay: what fields from the result should be displayed

/**
 * Removed AppContext Wrapper in Search Bar (Initial investigation)
 *
 * Reason: It affects how formik rerenders the values,
 *         When formik initialValues is passed, it is the only thing that
 *         formik use. AppContext always change the initialValue passed in formik.
 *
 *
 * Side Effects with AppContext:
 *  1. When searching an employee, the selected employee will be captured by the AppContext,
 *     while this is not wrong, it makes the AppContext the container of all values of Formik
 *     resulting on changing the state of AppContext and passing it again on formik.
 *
 *     AppContext InitialValue -> Formik -> Search Employee -> Choose Employee ->
 *     Captures by Formik and at the same time change the state of AppContext ->
 *     which will trigger reinitializing the inititalValues of Formik.
 *
 * 2. Data will not be played by formik anymore. Searching Employee with AppContext involves Rotating the data.
 *    AppContext data will always be the priority.
 *    This will cause inconsistency between the value that formik changed and the data that is passed
 *    on AppContext.
 *
 *    AppContext InitialValue -> Formik -> Add a Medicine -> This will cause a push data on the formik
 *    initialValue (inventory_logs) but not on the AppContext Data. -> Change the employee selected, and
 *    the whole formik values will reset (The inventory_logs will be cleared out).
 *
 *
 *
 * Latest Version Update Notes:
 *
 * 1. Added support for Formik Field
 * 2. Move the onClick function to tr
 */
const SearchBar = ({
  label,
  isRequired,
  type,
  name,
  placeholder,
  endpoint,
  resultDisplay,
  optionList,
  callbackFunction,
  field,
  form,
  props,
  itemDescription,
  additionalParams,
  displayResults,
  keyPressCallback,
  isAutocomplete,
  leftAddon,
  hasLeftAddon,
  isExpanded,
  children,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setSearchResults] = useState(optionList || []);
  const [selectedResult, setSelectedResult] = useState(null);
  const [highlightedResultIndex, setHighlightedResultIndex] = useState(0);
  const [highlightedResult, setHighlightedResult] = useState({});
  const [delayed, setDelayed] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    api
      .get(
        `${endpoint}/?search=${searchQuery}${
          additionalParams ? `&${additionalParams}` : ``
        }`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        }
      )
      .then(response => {
        setSearchResults(response.data.results);
        setHighlightedResultIndex(0);
        setHighlightedResult(response.data.results[0]);
        setLoading(false);
      })
      .catch(error => setLoading(false));
  };

  useEffect(() => {
    if (!searchQuery.length) {
      setDelayed(null);
      setSearchResults(optionList || []);
    }
    if (!selectedResult) {
      setLoading(true);
      if (!optionList) {
        if (delayed) clearTimeout(delayed);
        if (!!searchQuery) setDelayed(setTimeout(fetchData, 1000));
      } else {
        setSearchResults(optionList);
      }
    }
  }, [searchQuery, additionalParams]);

  useEffect(() => {
    if (results) setHighlightedResult(results[highlightedResultIndex]);
  }, [highlightedResultIndex]);

  const autoFocus = useRef(null);

  useEffect(() => {
    if (autoFocus.current) autoFocus.current.focus();
  }, []);

  return (
    <Fragment>
      {!!label && (
        <label
          className={classNames("label", {
            "has-text-weight-normal has-text-grey-dark": !isRequired,
          })}
        >
          {label}
          {!!isRequired && <span className="has-text-danger"> *</span>}
        </label>
      )}
      <div
        className={classNames("field", {
          "is-grouped": !!hasLeftAddon || !!isExpanded,
          "has-addons": !!hasLeftAddon,
        })}
      >
        {hasLeftAddon && leftAddon}
        <p
          className={classNames("control", {
            "has-icons-left": !isAutocomplete,
            "has-icons-right": true,
            "is-expanded": isExpanded,
          })}
        >
          <input
            type={type}
            className="input mb-1"
            name={name}
            placeholder={placeholder}
            value={selectedResult && selectedResult.title}
            ref={autoFocus}
            {...props}
            {...field}
            onKeyDown={event => {
              switch (event.key) {
                case "Tab":
                case "Enter":
                  if (keyPressCallback) {
                    keyPressCallback();
                  } else if (searchQuery && results.length > 0) {
                    const value = resultDisplay
                      .map(attribute => highlightedResult[attribute])
                      .join(" ");
                    if (callbackFunction) callbackFunction(highlightedResult);
                    setSearchQuery(value);
                    setSelectedResult(highlightedResult);
                    if (form) form.setFieldValue(field.name, value);
                  }
                  break;
                case "ArrowUp":
                  if (highlightedResultIndex > 0)
                    setHighlightedResultIndex(highlightedResultIndex - 1);
                  break;
                case "ArrowDown":
                  if (highlightedResultIndex < results.length - 1)
                    setHighlightedResultIndex(highlightedResultIndex + 1);
                  break;
                default:
                  break;
              }
            }}
            onChange={event => {
              setSearchQuery(event.target.value);
              setSelectedResult(null);
              if (form) form.handleChange(event);
            }}
          />

          {!isAutocomplete && (
            <span
              className={classNames("icon is-small is-left", styles.searchIcon)}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              ) : (
                <FontAwesomeIcon icon={faSearch} />
              )}
            </span>
          )}

          {/* {loading && (
            <span
            className={classNames(
              "icon is-small is-right",
                    styles.spinnerIcon
                    )}
                    >
                    <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </span>
                  )} */}
        </p>

        {children}
      </div>
      {!!searchQuery &&
        !!!selectedResult &&
        results.length > 0 &&
        displayResults && (
          <div>
            <table
              id="resultsTable"
              className={classNames(
                styles.searchResults,
                styles.searchTable,
                "table is-bordered is-hoverable search-results",
                {
                  [styles.moveSearchResults]: hasLeftAddon,
                }
              )}
            >
              <tbody>
                {results.map((result, index) => {
                  if (index >= highlightedResultIndex) {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          const value = resultDisplay
                            .map(attribute => result[attribute])
                            .join(" ");
                          if (callbackFunction) callbackFunction(result);
                          setSearchQuery(value);
                          setSelectedResult(result);
                          if (form) form.setFieldValue(field.name, value);
                        }}
                        className={classNames(
                          index === highlightedResultIndex &&
                            styles.highlightedResult
                        )}
                      >
                        <td>
                          {resultDisplay.map((attribute, index) => (
                            <span key={index}>{result[attribute]} </span>
                          ))}
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        )}

      {searchQuery &&
        results &&
        results.length === 0 &&
        displayResults &&
        !isAutocomplete && (
          <p className="help is-danger">
            No matching {itemDescription || "items"} found.
          </p>
        )}
    </Fragment>
  );
};

SearchBar.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  optionList: PropTypes.array,
};

SearchBar.defaultProps = {
  displayResults: true,
  isAutocomplete: false,
};

export default SearchBar;
