import React from "react";
import { Link } from "gatsby";

const NewMedCertButton = () => {
  return (
    <Link to="/medcerts/add">
      <button className="button is-medium is-primary is-pulled-right">
        + New Medical Certificate
      </button>
    </Link>
  );
};

export default NewMedCertButton;
