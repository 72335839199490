import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loader = ({size}) => (
  <div className="has-text-centered has-text-primary">
    <h1 className={`subtitle is-size-${size}`}>
      <FontAwesomeIcon icon={faSpinner} spin={true}/> Loading data...
    </h1>
  </div>
)

Loader.defaultProps = {
	size: 4
};

export default Loader;