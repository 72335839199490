import React, { useState, useEffect, useContext, Fragment } from "react";
import { navigate } from "gatsby";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import AppContext from "../../../context/AppContext";
import api from "../../../services/api";

import Input from "../../Templates/Forms/Input";
import SearchBar from "../../Templates/Forms/SearchBar";
import MedcertTabs from "./MedcertTabs";
import Loader from "../../Templates/Loader";
import DataTable from "../../Templates/DataTable";
import MedcertRow from "../MedcertRow";
import Pagination from "../../Templates/Pagination";

const MEDCERT_HEADERS = [
  "Priority",
  "Date Issued",
  "Employee Name",
  "Account",
  "Purpose",
  "Hospital Name",
  "Number of Leaves",
];

const MedcertLogs = ({ isAdmin, clinicId }) => {
  const appContext = useContext(AppContext);
  const [medcerts, setMedcerts] = useState([]);
  const [pages, setPages] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("Pending");
  const [isLoading, setLoading] = useState(false);

  const INITIAL_PARAMS = isAdmin
    ? { client: "all", page: 1 }
    : { clinic: clinicId, page: 1 };
  const [searchParams, setSearchParams] = useState(INITIAL_PARAMS);

  useEffect(() => {
    setLoading(true);
    if (!!clinicId && !!activeTab) {
      api
        .get(
          `/medcerts/report/?clinic=${clinicId}&status=${activeTab}${
            searchQuery ? `&employee=${searchQuery}` : ""
          }`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("loginToken")}`,
            },
          }
        )
        .then(response => {
          setMedcerts(response.data.results);
          setPages(Math.ceil(response.data.count / 30));
          setLoading(false);
        });
      appContext.setActiveMedcert({});
    }
  }, [activeTab, clinicId]);

  const searchData = employee => {
    setIsSearching(true);
    setSearchQuery(employee);
    setLoading(true);
    api
      .get(
        `/medcerts/report/?clinic=${sessionStorage.getItem(
          "clinicId"
        )}&status=${activeTab}&employee=${employee}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        }
      )
      .then(response => {
        setMedcerts(response.data.results);
        setPages(Math.ceil(response.data.count / 30));
        setLoading(false);
      });
  };

  const resetSearch = values => {
    setIsSearching(false);
    setLoading(true);
    if (values.employee) values.employee = "";
    setSearchQuery("");
    api
      .get(
        `/medcerts/report/?clinic=${sessionStorage.getItem(
          "clinicId"
        )}&status=${activeTab}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        }
      )
      .then(response => {
        setMedcerts(response.data.results);
        setPages(Math.ceil(response.data.count / 30));
        setLoading(false);
      });
  };

  const changePriority = (medcert, newPriority) => {
    setLoading(true);
    api
      .patch(`/medcerts/${medcert.id}/`, {
        status: activeTab,
        isPriority: newPriority,
      })
      .then(response => {
        api
          .get(
            `/medcerts/report/?clinic=${sessionStorage.getItem(
              "clinicId"
            )}&status=Pending${searchQuery ? `&employee=${searchQuery}` : ""}`,
            {
              headers: {
                Authorization: `${sessionStorage.getItem("loginToken")}`,
              },
            }
          )
          .then(response => {
            setLoading(false);
            setMedcerts(response.data.results);
            setPages(Math.ceil(response.data.count / 30));
          });
      });
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") event.preventDefault();
  };

  const callbackFunction = pageNumber => {
    setLoading(true);
    api
      .get(
        `/medcerts/report/?clinic=${sessionStorage.getItem(
          "clinicId"
        )}&status=${activeTab}&page=${pageNumber}${
          searchQuery ? `&employee=${searchQuery}` : ""
        }`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        }
      )
      .then(response => {
        setLoading(false);
        setMedcerts(response.data.results);
      });
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          employee: "",
        }}
      >
        {({ values }) => (
          <Form autoComplete="off" onKeyDown={handleKeyDown}>
            <div className="columns">
              <div className="column is-3">
                {appContext.siteDetails.id && !isAdmin && (
                  <Input
                    isRequired
                    name="employee"
                    value="employee"
                    render={({ field, form, props }) => {
                      return (
                        <SearchBar
                          placeholder="Search employee name..."
                          endpoint="consultations/employees"
                          field={field}
                          form={form}
                          props={props}
                          additionalParams={`site_id=${
                            appContext.siteDetails.id
                          }`}
                          resultDisplay={["firstName", "lastName"]}
                          callbackFunction={result => {
                            searchData(result.id);
                          }}
                        />
                      );
                    }}
                  />
                )}
              </div>
              <div className="column">
                {isSearching && (
                  <button
                    className="button"
                    onClick={() => resetSearch(values)}
                    type="button"
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="has-text-danger"
                    />
                  </button>
                )}
              </div>
              <div className="column" />
            </div>
          </Form>
        )}
      </Formik>
      <br />
      <MedcertTabs setActiveTab={setActiveTab} activeTab={activeTab} />
      <br />
      {isLoading ? (
        <Loader />
      ) : medcerts.length > 0 ? (
        <DataTable
          headerTitles={
            activeTab === "Pending" ? MEDCERT_HEADERS : MEDCERT_HEADERS.slice(1)
          }
        >
          {medcerts.map((medcert, index) => (
            <MedcertRow
              medcert={medcert}
              key={index}
              callbackFunction={() => {
                api.get(`medcerts/${medcert.id}/`).then(response => {
                  response.data.employeeName = medcert.employee.name;
                  response.data.email = medcert.email;
                  appContext.setActiveMedcert(response.data);
                  navigate(isAdmin ? "/admin/medcerts/view" : "/medcerts/view");
                });
              }}
              changePriority={changePriority}
            />
          ))}
        </DataTable>
      ) : (
        <p className="has-text-centered p-2">No data available.</p>
      )}

      <br />
      {pages > 1 && (
        <Pagination pages={pages} callbackFunction={callbackFunction} />
      )}
      <br />
    </Fragment>
  );
};

export default MedcertLogs;
