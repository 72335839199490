import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import NewMedCertButton from "../../components/Medcerts/NewMedCertButton";
import SEO from "../../layout/Seo";
import MedcertLogs from "../../components/Medcerts/MedcertLogs/MedcertLogs";

export default () => {
  const [clinicId, setClinicId] = useState(null);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setClinicId(window.sessionStorage.getItem("clinicId"));
    }
  }, []);

  return (
    <Layout
      pageTitle="Medical Certificate Validation"
      hasRightButton={<NewMedCertButton />}
    >
      <SEO title="Medcert Validation" />
      <MedcertLogs clinicId={clinicId} />
    </Layout>
  );
};
