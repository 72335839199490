import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglassHalf,
  faThumbsDown,
  faThumbsUp,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

const MedcertTabs = ({ medcerts, setActiveTab, activeTab }) => {
  const MedcertTab = ({ index, activeTab, activeText, activeColor, label }) => {
    const activeBackgroundColor = `has-background-${activeColor}`;
    const activeTextColor = `has-text-${activeText}`;
    return (
      <li
        className={classNames({
          "is-active": activeTab === label,
          [activeTextColor]: activeTab === label,
          [activeBackgroundColor]: activeTab === label,
        })}
        onClick={() => setActiveTab(label)}
      >
        <span>
          <FontAwesomeIcon icon={iconsArray[index]} className="mr-1" />
          {label}
        </span>
      </li>
    );
  };

  const iconsArray = [faHourglassHalf, faBan, faThumbsDown, faThumbsUp];
  const tabs = [
    {
      label: "Pending",
      activeColor: "warning",
      activeText: "black",
    },
    {
      label: "Unable to Verify",
      activeColor: "danger",
      activeText: "white",
    },
    {
      label: "Not Valid",
      activeColor: "light",
      activeText: "black",
    },
    {
      label: "Valid",
      activeColor: "primary",
      activeText: "white",
    },
  ];

  return (
    <div className={classNames("tabs is-boxed")}>
      <ul>
        {tabs.map((tab, index) => {
          return (
            <MedcertTab
              label={tab.label}
              activeText={tab.activeText}
              activeColor={tab.activeColor}
              activeTab={activeTab}
              key={index}
              index={index}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default MedcertTabs;
